/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| DECLARATION
|----------------
|
*/
$black: #000;
$very-dark-grey: #141414;
$dark-grey: #424242;
$grey: #999;
$light-grey: #dedede;
$dark-grey2: #dcdee2;
$very-light-grey: #fafafa;
$white: #fff;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

$blue: #0d1e46;
$blue-alt: #153c89;
$blue-alt2: #8086a0;
$blue-alt3: #183c89;
$dark-grey: #4d5c6b;
$grey: #b3b9bf;
$grey2: #e8e8e8;
$yellow: #f6de7c;
$orange: #f18b3e;
$purple: #9556a6;
$green: #45ae91;

/*
|
| DEFAULTS
|----------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

$blue: $blue;
$blue-alt: $blue-alt;
$blue-alt2: $blue-alt2;
$blue-alt3: $blue-alt3;
$dark-grey: $dark-grey;
$grey: $grey;
$dark-grey2: $dark-grey2;
$orange: $orange;
