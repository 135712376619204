.block-quote-img {
  position: relative;
  color: white;
  font-family: "Poppins";
  font-style: normal;

  img {
    max-width: 100%;
    max-height: 41rem;
  }
  h2 {
    color: #0d1e46;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    position: relative;
    &::before {
      content: "";
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      margin-top: 4px;
      margin-right: 15px;
      float: left;
      background-color: #0d1e46;
    }
  }

  .text {
    color: #0d1e46;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    font-weight: 300;
  }

  .elem-quote {
    svg {
      float: left;
      margin-top: 6px;
    }
    .offset {
      margin-left: 3rem;
      .quote {
        color: #0d1e46;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.75rem;
      }
      .author {
        color: #0d1e46;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
      }
      .function {
        color: #0d1e46;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5rem;
      }
    }
  }
}

.block-itt {
  position: relative;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  &.bg-itt {
    background: #f8f8f8;
  }
  .bg-img {
    display: flex;
    height: 41rem;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
  }
  .img {
    width: 100%;
  }
  h2 {
    color: #0d1e46;
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: 2rem;
    }
  }
  h3 {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.25rem;
  }

  .text {
    color: #0d1e46;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

.block-img-list {
  position: relative;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  overflow: hidden;
  .anim {
    top: initial !important; 
    bottom: -390px !important;
  }
  .img {
    max-width: 100%;
    max-height: 35rem;
    &.img-right {
      float: right;
    }
  }
  .title {
    color: #0d1e46;
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    .puce {
      text-transform: uppercase;
      &::before {
        content: "";
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        margin-top: 5px;
        margin-right: 15px;
        float: left;
        background-color: #0d1e46;
      }
    }
  }

  .text {
    color: #0d1e46;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
  }
}

.block-bigtext {
  .img {
    width: 100%;
  }
  .text {
    color: #0d1e46;
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2.25rem;
    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      line-height: initial;
    }
  }
}
