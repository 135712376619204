/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| spinner
|---------------
*/
.spinner {
    position: relative;

    &:before {
        content: "";

        display: block;

        margin: auto;

        width: 40px;
        aspect-ratio: 1;

        border: 4px solid $blue;
        border-radius: 50%;

        clip-path: polygon(0 0, 0 50%, 50% 50%, 50% 0);

        animation: 1s spinner linear infinite;
    }
}

@keyframes spinner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

/*
|
| Banner
|---------
*/
.banner {
    @if is-enabled("background-images") {
        @extend .bg-cover;
    }

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding-block: var(--header-height);
    background-color: $black;
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    .custom-card-link {
        display: block;
    }
    img {
        will-change: transform;
        transition: transform 2s $easeOutExpo;
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

/*
|
| Pagination
|---------------
*/
// .pagination-container {
//     li {
//         display: inline-block;

//         a {
//             transition: all 0.3s ease-out;
//             padding: 0 8px;

//             &:hover {
//                 color: $grey;
//             }
//         }

//         .current {
//             padding: 0 8px;
//             font-weight: 800;
//             color: $grey;
//         }
//     }
// }

/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: var(--header-height);
}

/*
|
| CMS
|------
*/
.cms {
    color: $blue;
    p,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:first-child) {
            margin-top: 1em;
        }
    }

    img {
        display: block;
        height: auto;
        max-width: 100%;
    }

    ul,
    ol {
        padding-left: 1.25em;

        li {
            &:not(:first-child) {
                margin-top: 0.25em;
            }
        }
    }

    ul {
        li {
            list-style: disc;
            position: relative;
            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }

    ol {
        li {
            list-style: decimal;
        }
    }

    a:not([class]) {
        text-decoration: underline;
    }
}

.fil-ariane {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    color: white;
    display: flex;
    margin-top: 3px;
    margin-left: 21px;
    .sep-ariane {
        height: 7px;
        display: block;
        margin: 8px 15px 10px 15px;
        border-top: 3px solid transparent;
        border-left: 3px solid white;
        border-bottom: 3px solid transparent;
    }
}

.deco {
    z-index: -1;
    background-size: cover;
    @include media-breakpoint-down(lg) {
        opacity: 0.3;
    }
    &.deco-l {
        width: 115px;
        height: 152px;
        background-image: url("../img/global/deco-l.svg");
    }
    &.deco-i {
        width: 106px;
        height: 173px;
        background-image: url("../img/global/deco-i.svg");
    }
    &.deco-o {
        width: 90px;
        height: 90px;
        background-image: url("../img/global/deco-o.svg");
    }
}

.dots {
    display: flex;
    span {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #fff;
        display: block;
        margin-right: 7px;
    }
    &.dots-alt {
        span {
            background-color: #0d1e46;
        }
    }
}

.anchor {
    display: inline-block;
    .scroll {
        color: $blue;
        font-size: 12px;
        font-family: Poppins;
        line-height: 22px;
        span {
            height: 45px;
            width: 45px;
            border: 1px solid $blue;
            border-radius: 50%;
            margin-right: 20px;
            &:before {
                content: "";
                width: 45px;
                height: 17px;
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("../img/global/arr_down.svg");
            }
        }
    }
}

.title-offset {
    h2 {
        color: #0d1e46;
        font-size: 56px;
        font-family: Satoshi;
        line-height: 56px;
        text-transform: uppercase;
        @include media-breakpoint-down(xl) {
            font-size: 36px;
            line-height: 40px;
        }
        @include media-breakpoint-down(lg) {
            font-size: 32px;
            line-height: 38px;
        }
        span {
            display: block;
            color: #153c89;
            padding-left: 210px;
            @include media-breakpoint-down(lg) {
                padding-left: 0;
            }
        }
    }
}
