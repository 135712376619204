.more {
    overflow: hidden;
    .block-more-item {
        position: relative;
        overflow: hidden;
        .more-item {
            position: relative;
            display: flex;
            align-items: flex-end;
            padding: 32px 24px;
            background-position: center;
            background-position: center;
            background-size: cover;
            overflow: hidden;
            transform: scale(1); 
            transition: all ease 0.3s;
            height: 500px;
            @include media-breakpoint-down(xl) {
                height: 370px;
            }
            &:before {
                content: "";
                position: absolute;
                inset: 0;
                z-index: 1;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
            }

            &-title {
                color: $white;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 4px;
                text-transform: uppercase;
                @include media-breakpoint-down(xl) {
                    font-size: 14px;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 1.5rem;
                }
                &::before {
                    content: "";
                    height: 12px;
                    width: 12px;
                    display: block;
                    float: left;
                    background-color: #fff;
                    border-radius: 50%;
                    margin-top: 4px;
                    margin-right: 20px;
                }
            }

            @include media-breakpoint-down(md) {
                &:before {
                    background: linear-gradient(0, rgba($black, 0.45) 20%, transparent 64%);
                }

                &-content {
                    flex-direction: column;
                }
            }
            &:hover {
                transform: scale(1.05);
                transition: all ease 0.3s;
            }
        }
        .more-item-content {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            width: calc(100% - 60px);
            margin: auto;
            display: flex;

            .btn {
                color: white;
                background: none;
                @include media-breakpoint-down(xl) {
                    font-size: 14px;
                }
                &:after {
                    content: "";
                    background-image: url(../img/icon/arrow-w.svg);
                }
            }
        }
        &:hover {
            .more-item {
                transform: scale(1.05);
                transition: all ease 0.3s;
            }
            .btn {
                color: white;
                padding: 15px 65px 15px 15px;
                transition: all ease 0.3s;
                &:after {
                    content: "";
                    width: 31px;
                    margin-right: 14px;
                    transition: all ease 0.3s;
                    background-image: url("../img/icon/arrow-w.svg");
                }
            }
        }
    }
}
