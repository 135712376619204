.block-push {
    background-color: #f0f7ff;
    .dot-right {
        top: inherit;
        bottom: 160px;
    }
    h2 {
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        text-transform: inherit;
    }
    .dots {
        display: inline-flex;
        margin-bottom: 30px;
    }
    .deco {
        margin: 0 auto 20px auto;
        display: table;
        span {
            height: 10px;
            width: 10px;
            border-radius: 50px;
            background-color: white;
            display: inline-block;
            margin: 0 3px;
        }
    }
}
