body {
  &.menu-active {
    overflow: hidden;
  }
}

/*
|
| Header
|---------
*/
%link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  z-index: 23;
  display: block;
  position: fixed;
  transition: all ease 0.5s;
  background-color: $blue;
  padding: 15px 0;

  @include media-breakpoint-down(lg) {
    padding: 0;
    height: 80px;
    border-bottom: 1px solid #636363;
    .item-logo {
      img {
        margin-top: 15px;
        height: initial !important;
      }
    }
  }
  .item-logo {
    transition: all ease 0.5s;
    img {
      height: 25px;
      max-width: 185px;
      transition: all ease 0.5s;
      @include media-breakpoint-down(lg) {
        margin-top: 28px;
      }
    }
  }
  &.top {
    padding: 30px 0;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
    .item-logo {
      padding-block: 12px;
      transition: all ease 0.5s;
      img {
        height: initial;
        max-width: 255px;
        transition: all ease 0.5s;
        @include media-breakpoint-down(lg) {
          margin-top: 0;
        }
      }
    }
    .sm-menu {
      top: 117px;
    }
  }

  .sm-menu {
    border-radius: 0px;
    border: 0px;
    top: 64px;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    padding: 35px 15px;
    background: #fff;
    /* box-shadow:
            0px 6px 8px -6px rgba(24, 39, 75, 0.12),
            0px 8px 16px -6px rgba(24, 39, 75, 0.08); */
    z-index: 9;
    display: none;
    .block-ss-menu {
      padding-top: 40px;
      padding-bottom: 10px;
      .intro {
        .title {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 4px;
          text-transform: uppercase;
        }
        .text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $dark-grey;
        }
      }
      .dflex {
        gap: 24px;
        align-items: start;
      }
      .content {
        .block-link {
          overflow: hidden;
          max-height: 290px;
          max-width: 290px;
          width: 100%;
          position: relative;
          aspect-ratio: 1 / 1;
          &:hover {
            .block-elem {
              transform: scale(1.03);
              transition: all ease 0.5s;
            }
          }
          .block-elem {
            max-height: 290px;
            max-width: 290px;
            aspect-ratio: 1 / 1;
            display: block;
            border-radius: 4px;
            background-size: cover;
            background-position: center;
            position: relative;
            transition: all ease 0.5s;
          }
        }
        .link {
          color: #0d1e46;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 4px;
          text-transform: uppercase;
          display: block;
        }
      }
    }
  }
  .header-container {
    position: relative;
    @include media-breakpoint-down(lg) {
      height: 80px;
    }
    .btn {
      @include media-breakpoint-down(lg) {
        position: absolute;
        right: 50px;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .item-nav {
      position: absolute;
      right: 0;
      @include media-breakpoint-down(lg) {
        display: none;
      }

      .item-menu {
        display: flex;
        margin: auto;

        .menu-item {
          position: static;
          margin-left: 10px;
          position: relative;
          cursor: pointer;
          position: relative;
          transition: all ease 0.3s;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 0%;
            height: 1px;
            background-color: white;
            transition: all ease 0.3s;
          }
          .item-link {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #ffffff;
            position: relative;
            &:hover {
              position: relative;
            }
          }

          &:not(:last-child) {
            .item-link {
              margin-right: 10px;
            }
          }
          .nav-drop {
            display: none;
            position: absolute;
            li {
              margin-left: 0;
              a {
                color: #ffffff;
              }
            }
          }
          &:hover,
          &.current-menu-item {
            position: relative;
            transition: all ease 0.3s;
            &:after {
              content: "";
              // width: calc(100% - 30px);
              width: 100%;
              height: 1px;
              background-color: white;
              transition: all ease 0.3s;
            }

            &:not(:last-child) {
              &::after {
                width: calc(100% - 10px);
              }
            }
          }

          &.menu-item-has-children {
            position: relative;
            margin-right: 20px;
            .item-link {
              padding-right: 0px;
              &:before {
                content: "";
                background-image: url(../img/global/arr-menu.svg);
                height: 5px;
                width: 7px;
                display: block;
                transform: rotate(0deg);
                transition: all ease 0.3s;
                background-size: cover;
                position: absolute;
                right: -15px;
                left: initial;
                top: 6px;
              }
              &:after {
                content: "";
                width: calc(100% - 0px)!important; 
              }
            }
            &.active {
              .item-link {
                &:before {
                  content: "";
                  transform: rotate(-180deg);
                  transition: all ease 0.3s;
                }
              }
            }
            .no-link {
              padding: 34px 30px 34px 0;
            }
          }
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition:
        opacity 1.2s $easeSmooth,
        transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: absolute;
    top: 30px;
    right: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: rgb(233, 233, 233);
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  width: 100%;
  height: 100vh;

  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;

  padding: var(--header-height) var(--side-margin);

  align-items: center;

  background-color: $blue;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  &:after {
    content: "";
    width: 24px;
    height: 168px;
    display: block;
    background-size: contain;
    position: absolute;
    bottom: 30px;
    right: 0;
    background-image: url(../img/global/deco-menu.svg);
  }
  ul {
    margin: 0 auto;

    li.menu-item {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      &.menu-item-has-children {
        &.opacity {
          opacity: 0.5 !important;
          pointer-events: none;
        }
        .link-parent {
          display: inline-block;
          .chevron {
            background-image: url(../img/global/chevron-down.svg);
            height: 24px;
            width: 24px;
            display: block;
            transform: rotate(0deg);
            margin-left: 15px;
            margin-top: 5px;
            transition: all ease 0.3s;
            background-size: cover;
            float: right;
            &.rotate {
              transition: all ease 0.3s;
              transform: rotate(180deg);
            }
          }
        }
      }

      a,
      span {
        color: var(--white, #fff);
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }

      .nav-drop {
        display: none;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: 40px;
        li {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
          a {
            color: var(--white, #fff);
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
          }
        }
      }
    }
  }
}
