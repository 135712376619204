
.block-key-figures-blue {
    background-color: #0d1e46;
    h2 {
        color: white!important;
        span {
            color: white!important;
        }
    }
    .item {
        &-number {
            color: white!important;
            font-family: Satoshi;
            font-size: 56px;
            font-style: normal;
            font-weight: 400;
            line-height: 56px; /* 100% */
        }
        &-title,
        &-text {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: white;
            ul {
                list-style: none;
                padding-left: 15px;
                padding-top: 10px;
                padding-bottom: 10px;
                li {
                    &:before {
                        content: "\2022";
                        color: #9dc6f3;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }
    }
}

.block-key-figures-frame {
    position: relative;
    .nbr-zone {
        background: #0d1e46;
        .title-number {
            color: white;
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            @include media-breakpoint-down(sm) {
                font-size: 32px;
                line-height: 36px;
            }
        }
        .number {
            height: 270px;
            padding: 30px;
            background-color: #fff;
            @include media-breakpoint-down(md) {
                margin-top: 0 !important;
                margin-bottom: 0;
                height: initial;
            }
            .chiffre {
                color: #0d1e46;
                font-family: Satoshi;
                font-size: 56px;
                font-style: normal;
                font-weight: 400;
                line-height: 56px;
                sup {
                    text-transform: capitalize;
                    font-size: 35px;
                }
            }
            .unity {
                color: #0d1e46;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}