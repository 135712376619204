/*
|--------------------
|      NEWS
|--------------------
*/

.load-more-container {
    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}

.block-hero__years {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    @include media-breakpoint-down(lg) {
        justify-content: initial;
    }
    &-select {
        $select: &;
        position: relative;
        color: $blue;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
        padding-right: 22px;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 5px;
            width: 7px;
            aspect-ratio: 1;
            margin: auto;
            border: {
                right: 2px solid $blue;
                bottom: 2px solid $blue;
            }

            transform: rotate(45deg);
        }

        &-list {
            position: absolute;
            top: calc(100% - 1px);
            left: -15px;
            right: 0;
            z-index: 1;
            font-size: 16px;
            line-height: initial;
            padding: 0 1rem;
            color: $blue;
            background-color: #fff;
            max-height: 0;
            overflow: hidden;
            cursor: pointer;
            transition:
                max-height 0.6s,
                padding 0.6s;
        }

        &-item {
        }

        &:hover {
            #{$select}-list {
                padding: 3px 15px;

                max-height: 300px;
            }
        }
    }
}
.events {
    margin: 80px 0 80px;

    &-months {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 30px 0;
        overflow: auto;

        &-item {
            position: relative;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            text-transform: uppercase;
            cursor: pointer;
            opacity: 0.5;
            color: $blue;
            padding: 0 21px;
            @include media-breakpoint-down(xl) {
                padding: 0 8px;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -15px;
                height: 2px;
                background-color: #d1d6dc;
                transition: right 0.6s;
            }

            &.--active,
            &:hover {
                opacity: 1;
                &:after {
                    content: "";
                    background-color: $blue;
                    transition: right 0.6s;
                }
            }
        }
    }

    &-list {
        margin: {
            top: 64px;
        }
    }

    &-card {
        --cc: 24;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 60px;

        &-day {
            color: $blue;
            font-family: Satoshi;
            font-size: 56px;
            font-style: normal;
            font-weight: 400;
            line-height: 56px;
            text-transform: uppercase;
        }

        &-month {
            color: $blue;
            text-transform: capitalize;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        &-header {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        &-time {
            display: flex;
            align-items: center;
            gap: 8px;

            &-clock {
                position: relative;

                min-width: 20px;
                aspect-ratio: 1;

                border-radius: 50%;
                border: 2px solid $dark-grey;

                &:before {
                    content: "";

                    position: absolute;
                    top: 2px;
                    left: 0;
                    right: 0;

                    margin: auto;

                    width: 2px;
                    height: 8px;

                    background-color: $dark-grey;
                    border-radius: 99px;
                }

                &:after {
                    content: "";

                    position: absolute;
                    top: 3px;
                    left: 0px;
                    right: 2px;

                    margin: auto;

                    width: 2px;
                    height: 6px;

                    background-color: $dark-grey;
                    border-radius: 99px;

                    transform: rotate(112.5deg);
                    transform-origin: center bottom;
                }
            }
        }

        &-type {
            color: $blue;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1;
        }

        &-title {
            color: $blue;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            margin: {
                top: 20px;
                bottom: 8px;
            }
        }

        &-text {
            color: $dark-grey;

            margin: {
                bottom: 16px;
            }
        }

        &-image {
            width: 100%;
            aspect-ratio: 415 / 233;

            border-radius: 4px;

            img {
                display: block;
                width: 100%;
                height: 100%;

                object-fit: cover;
                object-position: center;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &-months {
            gap: 16px;
        }

        &-card {
            &-day {
                text-align: left;
            }
            &-month {
                line-height: 1;
                text-align: left;
            }
        }
    }
}

.posts {
    &-title {
        font-size: 2.5rem;
        font-weight: 600;
    }

    &-container {
        margin: {
            top: 40px;
        }

        .row {
            --gy: 48px;
        }
    }
}

.filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    margin: {
        top: 32px;
    }

    &-item {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: white;
        line-height: 1;
        font-weight: 400;

        padding: 13px 24px;

        border-radius: 56px;
        background-color: #868ea2;
        transition:
            color 0.6s,
            background-color 0.6s,
            border-color 0.6s;

        cursor: pointer;

        &.--active,
        &:hover {
            color: $white;
            background-color: $blue;
            border-color: $blue;
        }
    }

    @include media-breakpoint-down(sm) {
        justify-content: center;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;

    margin: {
        top: 64px;
    }

    &-prev,
    &-next {
        position: relative;

        min-width: 40px;
        aspect-ratio: 1;

        margin: {
            right: 24px;
        }

        background-color: #e8e8e8;
        border-radius: 50%;

        cursor: pointer;

        &:before {
            content: "";

            position: absolute;
            inset: 0;
            left: 3px;

            margin: auto;

            width: 10px;
            aspect-ratio: 1;

            border: {
                left: 2px solid $blue;
                bottom: 2px solid $blue;
            }

            transform: rotate(45deg);
        }
    }

    &-next {
        margin: {
            left: 24px;
            right: 0;
        }

        &:before {
            left: 0;
            right: 3px;

            border: {
                top: 2px solid $blue;
                right: 2px solid $blue;
                left: none;
                bottom: none;
            }
        }
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 40px;
        aspect-ratio: 1;

        color: #bec5d6;
        font-weight: 600;

        cursor: pointer;

        &.--active {
            color: $blue;
        }
    }
}

.press {
    display: inline-block;

    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 4px 24px rgba($black, 0.06);

    margin: 80px auto 64px;
    padding: 60px 100px;

    text-align: center;

    &-container {
        text-align: center;
    }

    &-title {
        color: $blue;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1;
    }

    &-image {
        width: 120px;
        aspect-ratio: 1;

        border-radius: 50%;
        overflow: hidden;

        margin: 48px auto 24px;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
            object-position: center;
        }
    }

    &-name {
        color: $blue;
        font-size: 1.375rem;
        line-height: 1;
    }

    &-phone {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        margin: 24px auto;
        padding: 13px 24px;

        background-color: $blue-alt;
        border-radius: 8px;

        color: $white;
        font-weight: 600;

        &:before {
            content: "";

            width: 20px;
            height: 20px;

            background-image: url("../img/icon/phone.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &-email {
        display: block;

        color: $blue;
        font-weight: 600;
    }

    @include media-breakpoint-down(sm) {
        padding: 32px 16px;
    }
}

.press-container {
    position: fixed;
    right: 0;
    bottom: 80px;
    padding: 10px 20px;
    background: #f0f7ff;
    text-align: left;
    z-index: 1;
    .press-title {
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #7f8aa2;
    }
    .press-name {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: #0d1e46;
    }
    .press-tel {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #0d1e46;
    }
    .icone {
        display: block;
        margin: 0 10px 0 30px;
        img {
            height: 40px;
            width: 40px;
        }
    }
}
