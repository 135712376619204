/*
|--------------------
|       404
|--------------------
*/

#page-404 {
    /*
    |
    | Section contact
    |------------------
    */
    .section-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50vh;
        background-color: $white;
        color: $blue;
        text-align: center;

        .item-title {
            color: $blue;
            font-size: 70px;
            font-weight: 300;
            line-height: 1;
            @include media-breakpoint-down(md) {
                font-size: 34px;
            }
        }
    }
}
