/* Gravity form */
.gform_confirmation_message {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #0f1214;
    min-height: 600px;
}

.gform_wrapper.gravity-theme {
    .validation_message {
        font-family: inherit;
        text-transform: inherit;
    }
    .validation_message {
        padding: 3px 10px;
    }
    .gform_ajax_spinner {
        position: absolute;
        margin-top: 5px;
    }
    .gform_submission_error {
        text-transform: initial;
        font-size: 15px;
        font-weight: 700;
        &:after {
            content: none;
        }
        span {
            display: none;
        }
    }
    .gform_validation_errors {
        margin: 50px 50px 0px 50px;
        position: relative;
        width: calc(100% - 60px);
        padding: 10px;
        box-shadow: none;
        border: 1px solid black;
        border-radius: initial;
        display: none;
    }
    .gform_heading {
        display: none;
    }
    .gform-icon {
        display: none !important;
    }
    .gform_body {
        h2 {
            @include media-breakpoint-down(xl) {
                font-size: 38px;
                line-height: 43px;
            }
            &:after {
                content: none;
            }
        }
        .gform_required_legend {
            display: none;
        }
        .gform_title {
            display: none;
        }
        .gfield {
            margin-bottom: 10px;
            font-style: normal;
            font-size: 17px;
            line-height: 22px;
            color: #061c3d;
        }
        .gfield_label {
            font-style: normal;
            font-size: 17px;
            line-height: 22px;
            color: #1c1c1e;
            font-weight: 400;
        }
        .gfield--type-consent {
            border: none;
        }
        .ginput_container_date {
            .datepicker {
                width: 100%;
            }
        }

        .gfield_date_dropdown_day,
        .gfield_date_dropdown_month,
        .gfield_date_dropdown_year {
            max-width: 33.33%;
        }
        input[type="text"],
        input[type="email"],
        select,
        textarea {
            border-radius: 5px;
            font-size: 16px !important;
            line-height: initial;
            padding: 15px 15px !important;
            border: 1px solid rgba(230, 230, 230, 0.9);
        }
        .ginput_container_phone {
            input {
                border-radius: 5px;
                font-size: 16px !important;
                line-height: initial;
                padding-top: 15px !important;
                padding-bottom: 15px !important;
            }
        }
        textarea {
            height: 120px !important;
        }
        select {
            position: relative;
            background-repeat: no-repeat;
            background-size: 8px;
            background-position: calc(100% - 20px) center;
            background-image: url("../img/global/arr-menu.svg");
        }
        .ginput_container {
            .gfield_consent_label {
                font-weight: 400;
                font-size: 17px;
                line-height: 20px;
                color: #0f1214;
                margin-left: 28px;
                a {
                    text-decoration: underline;
                }
            }
        }
        .ginput_container_consent {
            position: relative;
            input[type="checkbox"] {
                position: absolute;
                cursor: pointer;
                border: none;
                left: 0;
                top: 0px;
                padding: 0 !important;
                margin: 0;
            }

            input[type="checkbox"]:before {
                content: "";
                display: block;
                position: absolute;
                width: 21px;
                height: 21px;
                top: 0;
                left: 0;
                border: 2px solid $blue;
                border-radius: 0px;
                background-color: transparent;
            }
            input[type="checkbox"]:checked {
                background-color: black;
                height: 20px;
                width: 20px;
                border-radius: 0px;
            }
            input[type="checkbox"]:checked:after {
                content: "";
                display: block;
                position: absolute;
                top: 2px;
                left: 7px;
                border: solid white;
                border-width: 0px 2px 2px 0;
                display: inline-block;
                padding: 3px 2px 8px 3px;
                transform: rotate(45deg);
            }
        }
    }
    .gform_footer {
        width: 100% !important;
        position: relative;
        display: inline-block !important;
        margin: 25px 0 0 0 !important;
        padding: 25px 0 0 0 !important;
        border-top: 1px solid #e7e7e8;
        @include media-breakpoint-down(md) {
            text-align: center !important;
        }
        input[type="submit"] {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            border: 1px solid $blue;
            background-color: $blue;
            display: inline-block;
            position: relative;
            padding: 13px 20px;
            transition: all ease 0.3s;
            margin-top: 15px;
            margin-bottom: 0;
            color: white;
            &:hover {
                border: 1px solid $blue;
                color: $blue;
                background-color: transparent;
                transition: all ease 0.3s;
                cursor: pointer;
            }
        }
    }
    .iti.iti--allow-dropdown input {
        border: none !important;
        border: 1px solid $dark-grey;
    }
    .required {
        font-size: 12px;
    }
}

.gform_wrapper.gravity-theme .gfield_validation_message,
.gform_wrapper.gravity-theme .validation_message {
    color: $orange !important;

    padding: 0 !important;

    background-color: transparent !important;
    border: none !important;
}

.gform_wrapper.gravity-theme .gfield_error [aria-invalid="true"] {
    border-color: $orange !important;
}

/* Custom */
.gform_wrapper {
    .gravity-theme {
        .gfield_label {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400 !important;
            font-size: 16px !important;
            line-height: 25px;
            color: #060f23;
        }
    }
    .gfield_required {
        color: $blue !important;
    }
    .gfield_description {
        font-family: "Poppins" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 18px !important;
        color: $dark-grey !important;
    }
}
