/* Plyr */
.videoyt {
    width: 100%;
    overflow: hidden;

    .plyr {
        .item-poster {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            opacity: 1;
            transition: all ease 0.4s;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .plyr__control--overlaid {
        background-image: $orange;
        padding: 30px;
        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        svg {
            color: black !important;
            height: 18px;
            width: 18px;
        }
    }

    .plyr__control--overlaid:hover {
        background-image: linear-gradient(90deg, black 0%, black 49%, black 100%);

        svg {
            color: white !important;
        }
    }

    .plyr--video {
        background: transparent !important;
    }

    .plyr__video-wrapper {
        background: transparent !important;
    }

    .plyr--full-ui input[type="range"] {
        color: white;
    }

    .plyr__video-embed iframe {
        top: -50%;
        height: 200%;
        width: 101%;
    }

    .plyr__poster {
        background-size: cover;
    }

    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded="true"] {
        background: black;
    }

    .plyr__control.plyr__tab-focus {
        box-shadow: 0 0 0 5px rgba(black, 0.5);
    }

    .plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
        background: black;
    }
}

/* Custom */
.videoyt {
    .plyr__control--overlaid {
        opacity: 1 !important;
        background-color: $blue;
        svg {
            color: white !important;
        }
        &:hover {
            opacity: 0.7 !important;
            background-color: white !important;
            svg {
                color: $blue !important;
            }
        }
    }
    .custom-plyr-video {
        height: 100%;
        width: 100%;
    }
}
