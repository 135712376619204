#page-joinus {
    .why {
        position: relative;
        z-index: 1;

        &-shape {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;

            width: 420px;
            height: 75px;

            background-color: $green;

            transform: rotate(-28deg) translateX(-50%);
        }

        .row {
            --cc: 24;

            justify-content: space-between;

            // > div {
            //     position: relative;
            // }
        }

        &-sticky {
            position: sticky;
            top: 120px;
        }

        &-item {
            display: flex;
            gap: 32px;

            &-number {
                color: transparent;
                font-size: 17.5rem;
                font-weight: 600;
                line-height: 0.8;
                text-align: center;
                -webkit-text-stroke: 1px $blue;

                opacity: 0.2;
            }

            &-title {
                color: #0d1e46;
                font-size: 16px;
                font-family: Poppins;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 4px;
                text-transform: uppercase;
                position: relative;
                &:before {
                    content: "";
                    height: 12px;
                    width: 12px;
                    display: inline-block;
                    float: left;
                    border-radius: 12px;
                    background: #0d1e46;
                    margin: 4px 15px 0px 0px;
                }
            }

            &-image {
                img {
                    max-width: 300px;
                    width: 100%;
                    margin: auto;
                    display: block;
                }
            }
        }

        @include media-breakpoint-down(md) {
            &-item {
                margin: {
                    left: 0 !important;
                    right: 0 !important;
                }

                & + .why-item {
                    margin: {
                        top: 32px;
                    }
                }

                &-number {
                    font-size: 7rem;
                }

                &-image {
                    margin: {
                        top: 16px;
                    }
                }
            }
        }
    }

    .joinus-sticky {
        position: sticky;
        bottom: 0;
        z-index: 1;

        text-align: center;

        &-container {
            padding: 16px 32px;

            display: inline-flex;
            align-items: center;
            gap: 64px;

            background-color: $white;
            border-radius: 8px;
            box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
        }

        @include media-breakpoint-down(sm) {
            &-container {
                flex-direction: column;
                gap: 8px;
            }
        }
    }
}
