/*
|--------------------
|     PAGE HOME
|--------------------
*/
.home {
    .block-hero {
        min-height: 530px;
        z-index: 12;
        @include media-breakpoint-down(lg) {
            min-height: initial;
        }
        .hero {
            margin: 70px auto;
            position: relative;
            @include media-breakpoint-down(lg) {
                margin: 15px auto;
            }
            .bloc-right-banner {
                display: grid;
                position: absolute;
                bottom: -130px;
                z-index: 111;
                color: #0d1e46;
                background-color: #f0f7ff;
                @include media-breakpoint-down(lg) {
                    position: relative;
                    bottom: initial;
                }
            }
        }
    }
}
