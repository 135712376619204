/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
    .hero {
        margin: 0 auto;
    }
    .deco-l {
        right: 15%;
        bottom: -50px;
        position: absolute;
    }
    .deco-i {
        right: 0%;
        bottom: 170px;
        position: absolute;
    }
    .deco-o {
        right: 4%;
        bottom: -100px;
        position: absolute;
    }
}
