.block-mot-president,
.block-first-gouv {
    z-index: 1;
    overflow: hidden;
    position: relative;
    color: white;
    background-color: #0d1e46;
    .title {
        color: #fff;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 4px;
        text-transform: uppercase;
        position: relative;
        &:before {
            content: "";
            width: 120px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            left: -150px;
            top: 9px;
        }
    }
    .content {
        color: #fff;
        font-size: 24px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: 36px;
        position: relative;
        @include media-breakpoint-down(xl) {
            font-size: 20px;
            line-height: initial;
        }
    }
    .photo-img {
        width: 100%;
        height: 500px;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(xl) {
            height: 300px;
        }
    }
    img {
        max-width: 250px;
        max-height: 390px;
    }
    .name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: white;
    }
    .role {
        color: #fff;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
    }
    .dots {
        top: 215px;
        left: 15px;
        display: block;
        position: absolute;
        @include media-breakpoint-down(xl) {
            top: 125px;
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
        span {
            margin-bottom: 8px;
        }
    }
}
