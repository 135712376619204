.work {
    position: relative;
    z-index: 20;
    h2,
    h3 {
        color: #0d1e46;
        font-size: 56px;
        font-family: Satoshi;
        line-height: 56px;
        text-transform: uppercase;
        @include media-breakpoint-down(lg) {
            font-size: 28px;
            line-height: 32px;
        }
        span {
            display: block;
        }
    }
    h3 {
        font-weight: 400;
        color: #153c89;
    }
    .gap-title {
        --gy: 0px;
    }

    .block-work {
        z-index: 1;
        position: relative;
        background-color: #f8f8f8;
        .work-item {
            padding: initial;
            .work-item-title {
                color: #0d1e46;
                font-size: 16px;
                font-family: Poppins;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 4px;
                text-transform: uppercase;
                position: relative;
                &::before {
                    content: "";
                    height: 12px;
                    width: 12px;
                    display: inline-block;
                    float: left;
                    border-radius: 12px;
                    background: #0d1e46;
                    margin: 4px 15px 0px 0px;
                }
            }

            .work-item-text {
                margin: 16px 0 24px;
            }

            .height-image {
                height: 656px;
                position: relative;
                @include media-breakpoint-down(lg) {
                    height: initial;
                }
            }
            .work-item-image {
                width: 510px;
                height: 656px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                right: 0;
                @include media-breakpoint-down(xl) {
                    width: 100%;
                }
                @include media-breakpoint-down(lg) {
                    height: 280px;
                    background-size: cover;
                    background-position: center;
                    position: relative;
                }
            }

            &-content {
                display: flex;
                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                }
            }

            &:first-child {
                .height-image {
                    margin-top: -160px;
                    @include media-breakpoint-down(lg) {
                        margin-top: initial;
                    }
                }
            }
            &:last-child {
                .height-image {
                    margin-bottom: -200px;
                    @include media-breakpoint-down(lg) {
                        margin-top: initial;
                    }
                }
            }
            &.inverse {
                .work-item-image {
                    left: 0;
                    right: initial;
                }
            }
        }
    }

    .quote {
        margin: {
            top: 56px;
        }
    }

    @include media-breakpoint-down(lg) {
        &-container {
            flex-direction: column;
            gap: 64px;

            padding: 0 15px;
        }

        &-item {
            opacity: 1 !important;

            padding: 0 !important;

            &-title {
                font-size: 3rem;
            }

            &-content {
                flex-direction: column;
                gap: 24px;

                padding: 0;
            }

            &-text {
                width: auto;
            }
        }
    }
}
