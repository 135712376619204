.quote {
    $quote: &;

    position: relative;
    .dots {
        top: 215px;
        left: 15px;
        display: block;
        position: absolute;
        @include media-breakpoint-down(xl) {
            top: 125px;
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
        span {
            background-color: $blue;
            margin-bottom: 8px;
        }
    }

    &-text {
        color: #0d1e46;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px;
        i {
            min-width: 14px;
            display: inline-block;
        }
    }

    &-content {
        display: flex;
        align-items: center;
        gap: 32px;

        margin: {
            top: 32px;
        }
    }

    .photo-img {
        height: 500px;
        aspect-ratio: 2.3/3;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(xl) {
            height: 300px;
        }
    }
    img {
        max-width: 250px;
        max-height: 390px;
    }

    &-name {
        color: $blue;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
    &-role {
        color: $blue;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
    }

    &--blue {
        color: $white;

        padding: {
            top: 64px;
            left: 0;
        }

        #{$quote}-text {
            color: $white;
        }

        #{$quote}-image {
            width: 96px;
        }

        #{$quote}-name {
            color: $white;
        }
    }

    @include media-breakpoint-down(lg) {
        &-text {
            font-size: 1.2rem;
        }

        &-image {
            width: 64px;
        }
    }
}
