/*
|-------------
| Color classes creation
|-------------
| - How to use: add lines to create more "color classes"
| - Generated classes: .bg-{color} & .c-{color}
| - Exemple: .bg-black & .c-black
|
*/
$colors : (
  'black':           $black,
  'very-dark-grey':  $very-dark-grey,
  'dark-grey':       $dark-grey,
  'grey':            $grey,
  'light-grey':      $light-grey,
  'very-light-grey': $very-light-grey,
  'white':           $white,
  'blue':           $blue
);