/*
|--------------------
|      Demarche
|--------------------
*/
.block-demarche {
    .text {
        color: #0d1e46;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
    }
}
