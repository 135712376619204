/*
|--------------------
|      SINGLE
|--------------------
*/
#page-news-single {
    overflow: hidden;
    .block-hero {
        padding-top: 117px;
        background-color: #0d1e46;
        @include media-breakpoint-down(sm) {
            padding-top: 80px;
        }
        .dots {
            float: left;
            margin-top: 7px;
            margin-right: 10px;
        }
        .hero {
            margin: 0 auto;
        }
        h1 {
            font-size: 56px;
            line-height: 56px;
            font-family: "Satoshi";
            font-style: normal;
            text-transform: uppercase;
            color: #eef2fc;
            font-weight: 400;
            @include media-breakpoint-down(md) {
                font-size: 40px;
                line-height: 40px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 32px;
                line-height: 32px;
            }
        }
        .post-intro {
            color: $blue;
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            p {
                color: $blue;
            }
        }
        .intro {
            background: white;
            .block-bg {
                height: 248px;
                width: 100%;
                .bg {
                    height: 248px;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                }
            }
            .bdr-b {
                border-bottom: 1px solid #98c0eb;
            }
        }
    }
    .rs {
        border-top: 1px solid $grey2;
        padding-top: 40px;
        .icon {
            display: flex;
            vertical-align: middle;
            align-items: center;
            float: left;
            margin-right: 20px;
            a {
                height: 40px;
                width: 40px;
                border-radius: 50px;
                background: $blue;
                display: flex;
                vertical-align: middle;
                align-items: center;
                transition: all ease 0.3s;
                &:hover {
                    opacity: 0.7;
                    transition: all ease 0.3s;
                }
                img {
                    max-width: 30px;
                    max-height: 30px;
                    height: 27px;
                    margin: auto;
                }
            }
        }
    }
}
