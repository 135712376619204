.button-round {
    $btn-round: &;

    display: inline-flex;
    align-items: center;
    gap: 16px;

    color: $blue;
    font-weight: 600;

    &-title {
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        aspect-ratio: 1;

        background-color: $blue-alt;
        border-radius: 50%;

        overflow: hidden;

        svg {
            width: 12px;
            aspect-ratio: 1;
        }
    }

    &:hover {
        #{$btn-round}-icon {
            svg {
                animation: .4s ease-out 1 button-round ;
            }
        }
    }
}

@keyframes button-round {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(30px); }
    51%  { transform: translateX(-30px); }
    100%   { transform: translateX(0); }
}