.rse {
  background-color: #f0f7ff;
  border: 1px solid var(--blue-2, #9dc6f3);

  &-image {
    display: block;
    padding: 50px 15px;
    overflow: hidden;
    background: white;
    img {
      display: flex;
      margin: auto;
      max-width: 160px;
    }
  }

  &-title {
    font-size: 1.75rem;
    padding: 40px 40px 20px 40px;
    @include media-breakpoint-down(lg) {
      padding: 15px;
    }
  }

  &-text {
    color: var(--dark, #0d1e46);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    padding: 0 40px 10px 40px;
    @include media-breakpoint-down(lg) {
      padding: 15px;
    }
  }

  .btn {
    margin: 10px 56px 40px 40px;
    @include media-breakpoint-down(lg) {
      margin: 10px 15px 15px 15px;
    }
  }
}
