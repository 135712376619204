/*
|--------------------
|  Double colonne
|--------------------
*/
.block-double-colonne {
    position: relative;
    color: white;
    font-family: "Poppins";
    font-style: normal;
    h2 {
        margin-bottom: 60px;
    }
    .col1 {
        color: $blue;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
    }
    .col2 {
        color: $blue;
        font-size: 16px;
        font-family: Poppins;
        font-weight: 300;
        line-height: 24px;
        position: relative;
        border-left: 1px solid rgba(13, 30, 70, 0.2);
    }
    .item-col-2{
        display: flex;
        align-items: end;
    }
}
