/*
|--------------------
|     Gouvernance
|--------------------
*/
.block-quote {
  position: relative;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  background: #0d1e46;
  h2 {
    font-family: Satoshi;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    text-transform: uppercase;
    color: white;
    @include media-breakpoint-down(xl) {
      font-size: 42px;
      line-height: 50px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 32px;
      line-height: 40px;
    }
    &.title_quote {
      color: #9dc6f3 !important;
    }
    .subtitle_quote {
      color: white !important;
    }
  }
  .content {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: white;
  }
  .icon {
    display: flex;
    vertical-align: middle;
    align-items: center;
    float: left;
    margin-left: 15px;
    margin-bottom: 8px;

    a {
      height: 40px;
      width: 40px;
      border-radius: 50px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        max-width: 30px;
        max-height: 30px;
        margin: auto;
      }
    }
  }

  .poles {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-bottom: 30px;
    &-item {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 4px;
      text-transform: uppercase;
      position: relative;
      width: fit-content;
      cursor: pointer;
      transition: all ease 0.3s;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -13px;
        margin: auto;
        width: 0;
        height: 2px;
        background-color: white;
        transition: all ease 0.3s;
      }
      &.--active {
        &:before {
          width: 100%;
          transition: all ease 0.3s;
        }
      }
      &.--fade {
        opacity: 0.4;
      }
    }
    @include media-breakpoint-down(lg) {
      flex-direction: row;
      gap: 24px;
      overflow-x: auto;

      &-item {
        white-space: nowrap;
      }
    }
  }

  .intro-members {
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
    h2 {
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      color: #eef2fc;
      margin: auto;
    }
    h3 {
      font-weight: 500;
      font-size: 28px;
      color: #eef2fc;
    }
    .post {
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
    }
    .text,
    .text p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #eef2fc;
    }
    .image {
      width: 100%;
      max-width: 416px;
      max-height: 416px;
    }
  }
  .list-members {
    .member {
      &.--hide {
        display: none;
      }
      .image {
        width: 100%;
      }
      .name {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .post {
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
      }
    }
  }
  .link {
    height: 20px;
    width: 20px;
    display: inline-block;
    @include media-breakpoint-down(lg) {
      margin: auto;
    }
    img {
      fill: white;
    }
  }
  &.quote_white {
    color: $blue !important;
    background-color: #fff !important;
    h2 {
      color: $blue !important;
      .subtitle_quote {
        color: $blue !important;
      }
    }
    .content {
      color: $blue !important;
    }
    .icon {
      a {
        background: $blue;
      }
    }
    .photo-img {
      height: 500px;
      aspect-ratio: 2.3/3;
      background-position: center;
      background-size: cover;
      @include media-breakpoint-down(xl) {
        height: 300px;
      }
    }
    img {
      max-width: 250px;
      max-height: 390px;
    }
    .dots {
      top: 215px;
      left: 15px;
      display: block;
      position: absolute;

      @include media-breakpoint-down(xl) {
        top: 125px;
      }
      @include media-breakpoint-down(lg) {
        display: none;
      }
      span {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: $blue;
        display: block;
        margin-right: 7px;
        margin-bottom: 8px;
      }
    }
  }
  &.quote_slider {
    color: $blue !important;
    background-color: #fff !important;
  }
}
