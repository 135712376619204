.page-template-expertises,
.page-template-expertise {
    .hero {
        margin: 50px auto;
        .bloc-right-banner {
            max-height: 400px;
            aspect-ratio: 1/1;
            position: absolute;
            width: 100%;
            bottom: -110px;
            @include media-breakpoint-down(lg) {
                display: none;
            }
            .block-bg {
                width: 100%;
                height: initial;
                max-height: 400px;
                aspect-ratio: 1/1;
                position: absolute;
                .bg {
                    height: initial;
                    max-height: 400px;
                    aspect-ratio: 1/1;
                }
            }
        }
    }
    #page-expertise {
        .poles {
            background-color: #0d1e46;
            h2 {
                color: white;
                span {
                    color: white;
                }
            }
            &-item {
                &-number {
                    color: white;
                    font-family: Satoshi;
                    font-size: 56px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 56px; /* 100% */
                }
                &-title,
                &-text {
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    color: white;
                    ul {
                        list-style: none;
                        padding-left: 15px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        li {
                            &:before {
                                content: "\2022";
                                color: #9dc6f3;
                                font-weight: bold;
                                display: inline-block;
                                width: 1em;
                                margin-left: -1em;
                            }
                        }
                    }
                }
            }
        }
    }
}
