/*
|-------------------------------
| Title sizes classes creation
|-------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
$title-sizes: (
  'xs': (
    'min': 1rem,
    'max': 1rem,
  ),
  'sm': (
    'min': 1.125rem,
    'max': 1.125rem,
  ),
  'md': (
    'min': 1.5rem,
    'max': 1.5rem,
  ),
  'lg': (
    'min': 1.875rem,
    'max': 1.875rem,
  ),
  'xl': (
    'min': 1.875rem,
    'max': 3.125rem,
  ),
  'xxl': (
    'min': 2rem,
    'max': 3.875rem,
  )
);