#footer {
    background: $blue;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: white;
    @include media-breakpoint-down(md) {
        text-align: center;
    }
    .pre-footer {
        .deco {
            margin: 0 auto 20px auto;
            display: table;
            span {
                height: 10px;
                width: 10px;
                border-radius: 50px;
                background-color: white;
                display: inline-block;
                margin: 0 3px;
            }
        }
        h2 {
            text-align: center;
            font-size: 40px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            line-height: 48px;
            @include media-breakpoint-down(sm) {
                font-size: 20px;
                line-height: 32px;
            }
        }
        img {
            max-height: 100px;
            max-width: 300px;
        }
    }
    .border-top {
        border-top: 1px solid rgba(255, 255, 255, 0.18);
        @include media-breakpoint-down(md) {
            .row {
                --gy: 10px;
            }
        }
    }
    h2 {
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        color: #eef2fc;
    }
    h3 {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    .link-ft {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #eef2fc;
        display: block;
        transition: all ease 0.3s;
        &:hover {
            opacity: 0.8;
            transition: all ease 0.3s;
        }
    }
    .link {
        display: block;
        transition: all ease 0.3s;
        &:hover {
            opacity: 0.8;
            transition: all ease 0.3s;
        }
    }
    .rs {
        .dflex {
            float: right;
            display: inline-block;
            @include media-breakpoint-down(lg) {
                float: initial;
            }
            .icon {
                display: flex;
                vertical-align: middle;
                align-items: center;
                float: left;
                margin-left: 25px;
                @include media-breakpoint-down(md) {
                    margin: 0 10px;
                }
                a {
                    height: 40px;
                    width: 40px;
                    border-radius: 50px;
                    background-color: #fff;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    img {
                        max-width: 17px;
                        max-height: 17px;
                        margin: auto;
                    }
                }
            }
        }
    }
    .block-copy {
        text-align: center;
        margin: auto;
        li {
            text-align: center;
            display: inline-block;
            .elem {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: white;
            }
        }
    }
}
