.shape {
    position: absolute;
    z-index: 0;

    pointer-events: none;

    &--yellow {
        width: 120px;
        aspect-ratio: 1;

        border: {
            left: 24px solid $yellow;
            bottom: 24px solid $yellow;
        }

        // transform: rotate(-33deg) translate(25%, -50%);
    }

    &--green {
        width: 420px;
        height: 72px;

        background-color: $green;
    }

    &--orange {
        width: 480px;
        aspect-ratio: 1;

        background-color: $orange;
        border-radius: 50%;
    }

    &--purple {
        width: 300px;
        height: 300px;

        border-radius: 0 0 0 300px;
        border: {
            left: 48px solid $purple;
            bottom: 48px solid $purple;
        }
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}