.block-secteurs {
    overflow: hidden;
    position: relative;
    margin-top: -70px;
    z-index: 0;
    .intro {
        color: #0d1e46;
        font-size: 20px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    .text {
        color: #0d1e46;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        position: relative;
        padding-left: 25px;
        &:before {
            content: "";
            height: 100%;
            width: 1px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #4d5c6b;
        }
    }

    .elem-block {
        .bg-img {
            display: block;
            height: 368px;
            width: 368px;
            overflow: hidden;
            @include media-breakpoint-down(xl) {
                height: 298px;
                width: 298px;
            }
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
            .bg {
                display: block;
                height: 368px;
                width: 368px;
                background-size: cover;
                transition: all ease 0.4s;
                @include media-breakpoint-down(xl) {
                    height: 298px;
                    width: 298px;
                }
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }
        }
        &:hover {
            .bg-img {
                .bg {
                    transform: scale(1.05);
                    transition: all ease 0.4s;
                }
            }
        }
        .elem-title {
            color: #0d1e46;
            font-size: 20px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-top: 15px;
            display: block;
            @include media-breakpoint-down(lg) {
                text-align: center;
            }
        }
    }
}
.anim-sector {
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 380px;
    display: block;
    @include media-breakpoint-down(lg) {
        max-width: 270px;
        margin: auto;
    }
}
.anim-sector2 {
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 290px;
    display: block;
    border-radius: 50%;
    float: right;
    @include media-breakpoint-down(lg) {
        max-width: 270px;
        margin: auto;
        float: initial;
    }
}
