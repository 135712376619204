.btn {
    color: $blue;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    transition: all ease 0.3s;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 12px 72px 12px 0px;
    &:after {
        content: "";
        height: 8px;
        width: 40px;
        display: inline-block;
        position: absolute;
        top: calc(50% - 4px);
        right: 0;
        transition: all ease 0.3s;
        background-position: right;
        background-image: url("../img/icon/arrow-b.svg");
    }
    &:before {
        content: "";
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        display: block;
        position: absolute;
        background-color: #0d1e46;
    }
    &:hover {
        color: white;
        padding: 12px 56px 12px 16px;
        background-color: #0d1e46;
        transition: all ease 0.3s;
        &:after {
            content: "";
            width: 16px;
            margin-right: 16px;
            transition: all ease 0.3s;
            background-image: url("../img/icon/arrow-w.svg");
        }
    }
    &.btn-alt {
        color: white;
        background: transparent;
        &:before {
            content: "";
            background-color: white;
        }
        &:after {
            content: "";
            background-image: url("../img/icon/arrow-w.svg");
        }
        &:hover {
            color: $blue;
            background: white;
            &:after {
                content: "";
                background-image: url("../img/icon/arrow-b.svg");
            }
        }
    }
    &.btn-blue {
        color: white;
        background-color: none;
        border: 1px solid $blue-alt;
        &:after {
            content: "";
            background-image: url("../img/icon/arrow-w.svg");
        }
        &:hover {
            color: $blue-alt;
            background: white;
            border: 1px solid $blue-alt;
            &:after {
                content: "";
                background-image: url("../img/icon/arrow-b.svg");
            }
        }
    }
    &.btn-video {
        position: relative;
        display: flex;
        padding: 13px 20px 13px 15px;
        &:before {
            content: "";
            position: relative;
            top: 0;
            left: 0;
            height: 36px;
            width: 36px;
            float: left;
            margin-right: 15px;
            aspect-ratio: 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../img/icon/play.svg");
            transition: all ease 0.3s;
        }
        &:after {
            content: none;
        }
        &:hover {
            &:before {
                background-image: url("../img/icon/play-w.svg");
                transition: all ease 0.3s;
            }
            &:after {
                content: none;
            }
        }
    }
}
