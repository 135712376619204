.block-hero {
    width: 100%;
    min-height: 420px;
    background-color: $blue;
    position: relative;
    padding-top: 150px;
    color: white;
    z-index: 1;
    @include media-breakpoint-down(lg) {
        padding-top: 130px;
    }
    .hero {
        margin: 100px auto;
        @include media-breakpoint-down(lg) {
            margin: 0 auto;
        }
        > .row {
            --cc: 24;
            justify-content: center;
        }

        .block-head {
            overflow: initial;
            .back {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                position: relative;
                color: white;
                .triangle {
                    display: block;
                    float: left;
                    margin: 9px 10px 0 0;
                    border-top: 5px solid transparent;
                    border-right: 5px solid white;
                    border-bottom: 5px solid transparent;
                }
            }
            .post-infos {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: $grey;
                .cat {
                    color: white;
                }
            }
            .text {
                color: $dark-grey2;
            }
            .banner-intro {
                border-left: 1px solid #3d4b6b;
                padding-left: 20px;
            }
        }
        .block-bg {
            width: 100%;
            height: 300px;
            overflow: hidden;
            max-height: 185px;
            @include media-breakpoint-down(md) {
                right: initial;
            }
            .bg {
                height: 300px;
                width: 100%;
                max-height: 185px;
                background-size: cover;
                background-position: center;
            }
        }
        img {
            width: 300px;
            height: 300px;
            border-radius: 50%;
            margin: 30px auto;
            object-fit: cover;
            object-position: center;
            max-height: 185px;
            @include media-breakpoint-down(md) {
                width: 200px;
                height: 200px;
            }
        }

        .block-hero-shapes {
            .shape {
                &--green {
                    width: 320px;
                    height: 56px;

                    bottom: 0;
                    right: 12%;

                    transform: translate(-50%, -50%) rotate(30deg);
                }

                &--purple {
                    top: 120px;
                    right: -72px;
                }

                &--orange {
                    width: 112px;

                    top: 0;
                    right: 22%;
                    bottom: 0;

                    margin: auto;
                }

                &--yellow {
                    right: 8%;
                    bottom: 16%;

                    transform: rotate(64deg);
                }
            }
        }
        .bloc-right-banner {
            display: grid;
            z-index: 20;
            color: $blue;
            background-color: #f0f7ff;
            @include media-breakpoint-down(lg) {
                position: inherit;
                margin-bottom: 60px;
            }
            .infos {
                width: 100%;
                p {
                    color: $blue;
                } 
                &.infos-header-img{ 
                    position: absolute;
                    width: 100%;
                    margin-top: -20px;
                    @include media-breakpoint-down(lg) {
                        position: relative;
                    }
                    .block-bg{
                        height: 420px;
                        max-height: 420px;
                        .bg {
                            height: 420px;
                            max-height: 420px;
                        }
                    }
                }
            }
        }
    }

    &-info {
        display: flex;
        align-items: flex-start;
        gap: 24px;

        color: $white;

        & + & {
            margin: {
                top: 32px;
            }
        }

        &-icon {
            img {
                width: auto !important;
                height: auto !important;
                border-radius: 0 !important;
                margin: 0 !important;
            }
        }

        &-title {
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}
#page-news-archive {
    .block-hero {
        z-index: 1;
        overflow: initial;
    }
}
