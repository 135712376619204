.block-testimonies {
    overflow: hidden;
    position: relative;

    .row {
        --cc: 24;
        justify-content: center;
    }
    .title-testi {
        .row {
            --cc: 12;
        }
    }
    h2,
    h3 {
        color: #0d1e46;
        font-size: 56px;
        font-family: Satoshi;
        line-height: 56px;
        text-transform: uppercase;
        @include media-breakpoint-down(lg) {
            font-size: 28px;
            line-height: 32px;
        }
        span {
            display: block;
        }
    }
    h3 {
        font-weight: 400;
        color: #153c89;
    }
    .gap-title {
        --gy: 0px;
    }

    .intro {
        color: #0d1e46;
        font-size: 20px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }
    .text {
        color: #0d1e46;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        position: relative;
        padding-left: 25px;
        &:before {
            content: "";
            height: 100%;
            width: 1px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #4d5c6b;
        }
    }

    .dot {
        right: -540px;
        top: 0;
        bottom: 0;
        left: inherit;
        margin: auto 0;
    }
    .elem {
        width: 100%;
        position: relative;
        display: block;
        transition: all ease 0.7s;
        &:hover {
            transition: all ease 0.7s;
        }
        &.elem1 {
            margin-left: 150px;
            @include media-breakpoint-down(lg) {
                margin-left: 0;
            }
        }
        &.elem2 {
            @include media-breakpoint-down(lg) {
                margin-top: 0 !important;
                margin-left: 0;
            }
        }
        .bg-img {
            width: 100%;
            height: 224px;
            display: block;
            background-size: cover;
            background-position: top;
            z-index: 0;
            position: relative;
            button {
                color: white;
                font-size: 13px;
                padding: 8px 10px;
                &::before {
                    content: "";
                    margin-right: 5px;
                    height: 16px;
                    width: 16px;
                    display: block;
                    float: left;
                    background-image: url("../img/icon/ic-tem.svg");
                }
            }
            &.bg-img-video {
                &:before {
                    content: "";
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transition: all ease 0.3s;
                    background-color: black;
                }
                &:hover {
                    &:before {
                        content: "";
                        opacity: 0.2;
                        transition: all ease 0.3s;
                    }
                }
            }
        }
        h3 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            background: #0d1e46;
            color: white;
            text-transform: initial;
            padding: 24px;
            margin-left: 40px;
            margin-top: -35px;
            width: 100%;
            z-index: 1;
            position: relative;
            @include media-breakpoint-down(lg) {
                margin-left: 0;
                margin-top: -2px;
            }
        }
        .infos {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $dark-grey;
        }
    }
}
