.modal {
    $modal: &;
    color: white;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    padding: var(--side-margin);
    overflow: auto;
    transition: background-color 0.5s ease;
    opacity: 0;

    &-inner {
        padding: var(--side-margin);
        margin: auto;
        background-color: #fff;
        color: #000;
        opacity: 0;
        transform: translateY(var(--side-margin));
        transition:
            opacity 0.5s ease,
            transform 0.5s ease;
    }

    &[role="dialog"] {
        #{$modal}-inner {
            width: 100%;
            max-width: 54rem;
        }
    }

    &[role="alertdialog"] {
        #{$modal}-inner {
            width: fit-content;
        }
    }

    .content {
        margin: auto;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 800px;
        max-height: 100%;
        vertical-align: middle;
        position: absolute;
        inset: 0;
        z-index: 1;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        video {
            width: 100%;
            height: auto;
            max-height: 100%;
            border-radius: 6px;
        }
    }

    iframe {
        width: 100%;
        height: 450px;
        @include media-breakpoint-down(md) {
            height: 330px;
        }
        @include media-breakpoint-down(sm) {
            height: 230px;
        }
    }

    .close,
    .close-testimonial {
        cursor: pointer;
        position: relative;
        transition: all ease 0.3s;
        display: block;
        height: 8px;
        position: absolute;
        top: 38px;
        right: 21px;
        z-index: 1;
        span {
            position: absolute;
            right: 25px;
            top: -14px;
        }
        &:hover {
            opacity: 0.7;
            transition: all ease 0.3s;
        }

        &::before {
            content: "";
            height: 2px;
            width: 15px;
            display: block;
            background-color: white;
            transform: rotate(45deg);
            position: absolute;
        }

        &::after {
            content: "";
            height: 2px;
            width: 15px;
            display: block;
            background-color: white;
            transform: rotate(-45deg);
        }
    }

    &.modal-tem {
        .content {
            width: initial;
        }
    }
}

.mod {
    .modal {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #262626;
        }

        .modal-inner {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
