.custom-card {
    .card-img-container {
        width: 100%;
        height: 330px;
        overflow: hidden;
        background: #e8e8e8;
        display: block;
        .bg-image {
            background-size: cover;
            background-position: center;
            height: 330px;
            width: 100%;
            display: block;
            transform: scale(1);
            transition: all ease 0.4s;
        }
    }

    .details {
        padding-left: 25px;
        display: block;
        border-left: 1px solid var(--dark-20, rgba(13, 30, 70, 0.2));
        .title {
            color: var(--dark, #0d1e46);
            font-size: 20px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            display: block;
        }
        .intro {
            color: var(--dark-grey, #333c46);
            font-size: 14px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            overflow: hidden;
            text-transform: inherit;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            @include media-breakpoint-down(lg) {
                display: flex;
                align-items: center;
                vertical-align: middle;
            }
        }
        .block-bottom {
            @include media-breakpoint-up(xl) {
                display: flex;
                align-items: center;
                vertical-align: middle;
            }
            .cat,
            .date {
                color: var(--dark, #0d1e46);
                font-size: 12px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 2px;
                text-transform: uppercase;
                @include media-breakpoint-down(xl) {
                    display: block;
                }
            }
            .sep {
                @include media-breakpoint-down(xl) {
                    display: none;
                }
            }
        }
    }
    &:hover {
        .bg-image {
            transform: scale(1.05);
            transition: all ease 0.4s;
        }
    }
    .first-elem-actu {
        .title {
            font-size: 28px;
            line-height: 36px;
            @include media-breakpoint-down(lg) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .intro {
            font-size: 16px;
            line-height: 24px;
            @include media-breakpoint-down(lg) {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}
