#page-sector {
    .block-hero {
        .bloc-right-banner {
            background-color: transparent;
            .block-bg {
                max-height: 290px;
                max-width: 290px;
                height: 100%;
                width: 100%;
                aspect-ratio: 1/1;
                border-radius: 50%;
                .bg {
                    max-height: 290px;
                    max-width: 290px;
                    height: 100%;
                    width: 100%;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    background: white;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}
.testi {
    overflow: hidden;
    .swiper {
        overflow: visible;
    }
    .quote {
        border-left: 1px solid #3d4b6b;
        padding-left: 20px;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    &-nav {
        display: flex;
        gap: 16px;

        &-prev,
        &-next {
            position: relative;

            width: 40px;
            aspect-ratio: 1;

            background-color: $white;
            border-radius: 50%;
            cursor: pointer;

            transition: opacity 0.4;

            &.swiper-button-disabled {
                opacity: 0.2;
            }

            &:before {
                content: "";

                position: absolute;
                inset: 0;
                left: 3px;

                margin: auto;

                width: 8px;
                aspect-ratio: 1;

                border: {
                    left: 1px solid $blue;
                    bottom: 1px solid $blue;
                }

                transform: rotate(45deg);
            }
        }

        &-next {
            &:before {
                left: 0;
                right: 3px;

                transform: rotate(225deg);
            }
        }
    }

    &-progress {
        position: relative;

        max-width: 430px;
        height: 1px;

        margin: 64px auto 0;

        background-color: rgba(#eef2fc, 0.3);

        &-bar {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;

            min-width: 5%;
            width: 5%;
            height: 2px;

            margin: auto;

            background-color: $orange;
            border-radius: 10px;
        }
    }
}
.tti {
    overflow: hidden;
    position: relative;

    &-image {
        width: 60%;
        max-width: 100%;
        aspect-ratio: 2.2/3;
        overflow: hidden;
        @include media-breakpoint-down(xl) {
            width: 80%;
        }
        @include media-breakpoint-up(lg) {
            float: right;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &-item {
        gap: 24px;

        &-number {
            color: transparent;
            font-size: 6.875rem;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            -webkit-text-stroke: 1px $blue-alt;

            min-width: 64px;
        }

        &-title {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }
}
