.block-content {
    &-row {
        @extend .row;

        --gx: 80px;

        align-items: center;
        justify-content: center;
    }

    &--gradient {
        background: linear-gradient(180deg, #f2f5fc 0%, rgba(242, 245, 252, 0) 100%);
    }

    &-image {
        position: relative;

        &--padding {
            margin: {
                bottom: 150px;
            }
        }

        .shape {
            &--green {
                top: 20%;
                right: -88px;
                z-index: 2;

                width: 200px;
                height: 32px;

                transform: rotate(45deg);
            }
        }

        &-main {
            position: relative;
            z-index: 1;

            width: 100%;
            aspect-ratio: 3 / 4;

            border-radius: 4px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
                object-position: center;
            }
        }

        &-second {
            position: absolute;
            right: 30px;
            bottom: -150px;
            z-index: 1;

            max-width: 225px;
            width: 100%;
            aspect-ratio: 3 / 4;

            border-radius: 4px;
            box-shadow: 0px 32px 34px -2px rgba(9, 39, 78, 0.2);
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
                object-position: center;
            }
        }
    }

    &-sub {
        color: $blue;
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.4;

        margin: {
            top: 24px;
        }
    }

    @include media-breakpoint-down(lg) {
        &-sub {
            font-size: 1.25rem;
        }
    }
    &.image-full {
        .block-content-image {
            aspect-ratio: initial;
        }
    }
}
