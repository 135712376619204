#block-similar-actus {
    .custom-card-link {
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
        display: grid;
        overflow: hidden;
        position: relative;
    }
    .btn {
        float: right;
        @include media-breakpoint-down(lg) {
            float: initial;
        }
    }
}
