.anchors {
  position: relative;
  text-align: center;
  border-bottom: 2px solid var(--blue-3, #f0f7ff);
  background: var(--white, #fff);
  @include media-breakpoint-down(lg) {
    display: none;
  }
  &.anchor-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 23;
    transition: all ease 0.3s;
    @include media-breakpoint-down(lg) {
      top: 155px;
    }
  }
  .gap_anchors {
    gap: 40;
  }
  a {
    position: relative;
    padding: 24px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    color: #8a9dc4;
    &:after {
      content: "";
      height: 2px;
      width: 100%;
      background: #153c89;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      opacity: 0;
    }
    &.hover {
      opacity: 0.7;
      color: #153c89;
      &:after {
        content: "";
        background: #153c89;
      }
    }
    &.active {
      opacity: 1;
      color: #153c89;
      &:after {
        content: "";
        opacity: 1;
      }
    }
  }
}
