/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body {
    font-family: $font-family-default;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
    color: $default-color;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.menu-open {
        height: 100vh;
        overflow: hidden;
    }
}

.overlay {
    display: none;
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 22;
    transition: all ease 0.8s;
    background-color: #0d1e46;
    &.over-active {
        display: block;
        opacity: 0.8;
        transition: all ease 0.8s;
    }
}

.bgb {
    background: #f0f7ff;
}

h2,
h3,
h4,
h5,
h6 {
    font-family: "Satoshi";
    font-style: normal;
}
h1 {
    font-size: 72px;
    font-family: "Satoshi";
    font-style: normal;
    text-transform: uppercase;
    line-height: 80px;
    color: #eef2fc;
    font-weight: 400;
    @include media-breakpoint-down(xl) {
        font-size: 60px;
        line-height: 64px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 42px;
        line-height: 50px;
    }
    @include media-breakpoint-down(md) {
        font-size: 32px;
        line-height: 40px;
    }
}
h2 {
    font-size: 56px;
    line-height: 56px;
    color: $blue;
    font-weight: 400;
    @include media-breakpoint-down(xl) {
        font-size: 42px;
        line-height: 50px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 32px;
        line-height: 40px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 32px;
    }
}
button,
input,
select,
textarea {
    border: 0;
    border-radius: 0;

    background-color: transparent;

    appearance: none;

    letter-spacing: inherit;
    font-family: inherit;
    font-size: inherit;
}

a,
button,
select,
input,
textarea {
    color: inherit;

    &:focus,
    &:focus-visible {
        outline: none;
        outline-offset: 0;
    }
}

a,
button {
    cursor: pointer;
}

button {
    text-transform: inherit;
}

a {
    text-decoration: none;
}

ol,
ul {
    li {
        list-style-type: none;
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}

.col-auto {
    flex: 1 !important;
    height: initial;
}

.dflex {
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.m-a {
    margin: auto;
}

.txt-c {
    text-align: center;
}

.rel {
    position: relative;
}

.o-h {
    overflow: hidden;
}

.z1 {
    z-index: 1;
    position: relative;
}

.bg-blue-alt {
    background-color: $blue-alt;
}

.bg-light-grey {
    background-color: #f8f8f8;
}
.c-blue {
    color: #9dc6f3 !important;
}
.c-white {
    color: white !important;
}
.disp-desk {
    display: block !important;
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}

.disp-mob {
    display: none !important;
    @include media-breakpoint-down(lg) {
        display: block !important;
    }
}
.dot {
    height: 700px;
    width: 700px;
    display: block;
    top: 120px;
    left: -470px;
    @include media-breakpoint-down(lg) {
        opacity: 0.2 !important;
    }
    @include media-breakpoint-down(md) {
        display: none !important;
    }
    &.dot-left {
        position: absolute;
        animation: animdotl 120s linear infinite;
        background-image: url("../img/icon/point-jap-alt.svg");
    }
    &.dot-right {
        position: absolute;
        animation: animdotr 120s linear infinite;
        background-image: url("../img/icon/point-jap.svg");
        right: -390px;
        top: 70px;
        left: inherit;
    }
    &.dot-questions {
        position: absolute;
        animation: animdotr 120s linear infinite;
        background-image: url("../img/icon/point-jap-alt.svg");
        right: -390px;
        top: initial;
        bottom: -330px;
        left: inherit;
    }
}

@keyframes animdotl {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes animdotr {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.circle {
    width: 12px;
    height: 12px;
    display: block;
    background: #0d1e46;
    margin-bottom: 15px;
    border-radius: 50%;
}
.clearfix {
    clear: both;
    width: 100%;
}
.share-links {
    border-top: 1px solid #9DC6F3;
    padding-top: 30px;
    a {
        float: left;
        margin-right: 10px;
    
        .icon-rs {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            vertical-align: middle;
            align-items: center;
            border-radius: 3.05125rem;
            background: var(--dark, #0d1e46);
            svg {
                margin: auto;
            }
        }
    }
}
